





























































































































































































































import gql from 'graphql-tag';
import moment from 'moment';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { AuditLogEntry, AuditLogFilter, AuditLogResponse, AuditLogType } from '@/api-svc-types';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import { isDefined } from '@/utils/guards';

import { BaseVue } from '../BaseVue';
import UiAlert from '../components/ui/UiAlert.vue';
import UiDatePicker2 from '../components/ui/UiDatePicker2.vue';
import UiLoading from '../components/ui/UiLoading.vue';
import UiPagination from '../components/ui/UiPagination.vue';
import UiTextEdit from '../components/ui/UiTextEdit.vue';

@Component({
  components: { UiSelect2, UiTextEdit, UiDatePicker2, UiLoading, UiPagination, UiSelect, UiAlert },
  apollo: {
    auditLogTypes: {
      query: gql`
        query GetAuditLogTypes {
          auditLogTypes {
            name
            category
            entityType
            action
          }
        }
      `,
      loadingKey: 'isLoading',
      update(data) {
        return data.auditLogTypes;
      },
    },
    auditLogResult: {
      query: gql`
        query GetAuditLogs($orgId: ID!, $filter: AuditLogFilter, $paginationToken: String, $pageLimit: Int) {
          auditLogs(orgId: $orgId, filter: $filter, paginationToken: $paginationToken, pageLimit: $pageLimit) {
            items {
              id
              userId
              entityType
              entityId
              action
              timestamp
              userEmail
              message
              category
              name
            }
            previousPageToken
            nextPageToken
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          filter: this.auditLogFilterDebounced ?? undefined,
          paginationToken: this.paginationToken ?? undefined,
          pageLimit: Number(this.itemsPerPage),
        };
      },
      loadingKey: 'isLoading',
      fetchPolicy: 'cache-and-network',
      update(data) {
        return data.auditLogs;
      },
    },
    expandedAuditLog: {
      query: gql`
        query GetAuditLog($id: ID!, $withDetails: Boolean) {
          auditLog(id: $id, withDetails: $withDetails) {
            id
            userId
            entityType
            entityId
            action
            timestamp
            userEmail
            message
            category
            name
            detail
          }
        }
      `,
      variables() {
        return {
          id: this.expandedLogId,
          withDetails: true,
        };
      },
      skip() {
        return !this.expandedLogId;
      },
      loadingKey: 'logDetailLoading',
      update(data) {
        return data.auditLog;
      },
    },
  },
})
export default class AuditLog extends BaseVue {
  public filterCategories = ['allCategories'];
  public searchText = '';
  public beforeDate = new Date().toISOString().substring(0, 10);
  public expandedLogId = null as string | null;
  public logDetailLoading = 0;
  public itemsPerPage = '20';
  public isLoading = 0;
  public auditLogResult?: AuditLogResponse;
  public auditLogTypes?: AuditLogType[];
  public paginationToken: string | null = null;
  public filterEntityType = 'allEntityTypes';
  public filterEntityAction = 'anyAction';
  public filterEntityId = null as string | null;
  public filterUser = null as { userId: string; userEmail: string } | null;

  public get showUserFilter() {
    return this.filterUser !== null;
  }

  public set showUserFilter(val: boolean) {
    if (!val) {
      this.filterUser = null;
    }
  }

  public get auditLogs() {
    return (this.auditLogResult?.items ?? [])
      .map((x) => {
        return x
          ? {
              ...x,
              typeHierarchy: this.typeHierarchy(x),
            }
          : x;
      })
      .filter(isDefined);
  }

  public get entityTypes(): { display: string; id: string }[] {
    const raw = this.auditLogTypes?.map((x) => x.entityType) ?? [];
    raw.unshift('allEntityTypes');
    return [...new Set(raw)].map((x) => ({
      display: this.camelFormat(x),
      id: x,
    }));
  }

  public get actionsByEntityType() {
    // a map of entity type to array of all actions
    return (
      this.auditLogTypes?.reduce((a, x) => {
        if (!a[x.entityType]) {
          a[x.entityType] = [];
        }
        if (!a[x.entityType].includes(x.action)) {
          a[x.entityType].push(x.action);
        }
        return a;
      }, {} as Record<string, string[]>) ?? {}
    );
  }

  public get entityActions() {
    if (this.filterEntityType === 'allEntityTypes') {
      return [{ display: 'Any Action', id: 'anyAction' }];
    } else {
      const raw = ['anyAction', ...(this.actionsByEntityType[this.filterEntityType] ?? [])];
      return raw.map((x) => ({ display: this.camelFormat(x), id: x }));
    }
  }

  private filterChangeTimeout?: ReturnType<typeof setTimeout>;
  public auditLogFilterDebounced: AuditLogFilter | null = null;
  @Watch('auditLogFilter', { immediate: true })
  public onFilterChange() {
    if (this.filterChangeTimeout) {
      clearTimeout(this.filterChangeTimeout);
    }
    this.filterChangeTimeout = setTimeout(() => {
      this.auditLogFilterDebounced = this.auditLogFilter;
    }, 500);
  }

  public get auditLogFilter(): AuditLogFilter {
    const categories = this.filterCategories.filter((x) => x !== 'allCategories');
    const beforeTimestamp = moment(this.beforeDate)
      .add(1, 'day')
      .tz(this.$store.state.currentOrg.timezone, true)
      .unix();
    const filter: AuditLogFilter = {
      categories: categories.length ? categories : undefined,
      beforeTimestamp,
      userId: this.filterUser?.userId,
      action: this.filterEntityAction !== 'anyAction' ? this.filterEntityAction : undefined,
      entityType: this.filterEntityType !== 'allEntityTypes' ? this.filterEntityType : undefined,
      entityId: this.filterEntityId || undefined,
    };
    return filter;
  }

  public categoryNames: Record<string, string> = {
    walletsAndConnections: 'Wallets & Connections',
  };

  public categoryDetails: Record<string, { display: string; icon: string; color: string; id: string }> = {
    all: {
      display: 'All Categories',
      icon: 'fa-regular fa-circle',
      color: 'tw-text-gray-400',
      id: 'allCategories',
    },
    walletsAndConnections: {
      display: 'Wallets & Connections',
      icon: 'fa-regular fa-wallet',
      color: 'tw-text-blue-500', // 'tw-text-yellow-700',
      id: 'walletsAndConnections',
    },
    transactions: {
      display: 'Transactions',
      icon: 'fa-regular fa-table-list',
      color: 'tw-text-blue-500',
      id: 'transactions',
    },
    accounting: {
      display: 'Accounting',
      icon: 'fa-regular fa-building-columns',
      color: 'tw-text-blue-500', // 'tw-text-indigo-500',
      id: 'accounting',
    },
    reporting: {
      display: 'Reporting',
      icon: 'fa-regular fa-file-contract',
      color: 'tw-text-blue-500', // 'tw-text-purple-500',
      id: 'reporting',
    },
    inventory: {
      display: 'Inventory',
      icon: 'fa-regular fa-warehouse',
      color: 'tw-text-blue-500', // 'tw-text-pink-600',
      id: 'inventory',
    },
    arAp: {
      display: 'AR/AP',
      icon: 'fa-regular fa-money-bill-transfer',
      color: 'tw-text-blue-500', // 'tw-text-blue-700',
      id: 'arAp',
    },
    administration: {
      display: 'Administration',
      icon: 'fa-regular fa-folder-gear',
      color: 'tw-text-red-500', // 'tw-text-green-500',
      id: 'administration',
    },
    security: {
      display: 'Security',
      icon: 'fa-regular fa-shield-check',
      color: 'tw-text-red-500', // 'tw-text-red-500',
      id: 'security',
    },
  };

  public getCategoryDetail(cat: string): { display: string; icon: string; color: string } {
    return (
      this.categoryDetails[cat] || {
        display: this.camelFormat(cat),
        icon: 'fa-regular fa-circle',
        color: 'tw-text-gray-400',
      }
    );
  }

  public camelFormat(str: string): string {
    if (!str) {
      return '';
    }
    return str.replace(/[a-z][A-Z0-9]/g, (x) => `${x[0]} ${x[1]}`).replace(/\b[a-z]/g, (x) => x.toLocaleUpperCase());
  }

  public onFilterCategoriesChange(categories: string[]) {
    if (categories.length === 0) {
      this.filterCategories = ['allCategories'];
    } else if (categories[categories.length - 1] === 'allCategories') {
      this.filterCategories = ['allCategories'];
    } else if (categories.includes('allCategories')) {
      this.filterCategories = categories.filter((c) => c !== 'allCategories');
    } else {
      this.filterCategories = categories;
    }
  }

  public setFilter(filter: {
    category?: string;
    entityType?: string;
    entityAction?: string;
    entityId?: string;
    user?: { userId: string; userEmail: string };
  }) {
    if (this.categoryDetails[filter.category ?? '']) {
      this.filterCategories = [filter.category ?? ''];
    } else {
      this.filterCategories = ['allCategories'];
    }
    if (filter.entityType && this.entityTypes.some((x) => x.id === filter.entityType)) {
      this.filterEntityType = filter.entityType;
    } else {
      this.filterEntityType = 'allEntityTypes';
    }
    if (filter.entityAction && this.entityActions.some((x) => x.id === filter.entityAction)) {
      this.filterEntityAction = filter.entityAction;
    } else {
      this.filterEntityAction = 'anyAction';
    }
    this.filterEntityId = filter.entityId ?? null;
    this.filterUser = filter.user ?? null;
  }

  public localeDateTime(date: number): string {
    const dt = new Date(date * 1000);
    return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
  }

  public typeHierarchy(entry: AuditLogEntry): { entityType: string; action: string; display: string }[] {
    return [
      {
        entityType: entry.entityType,
        action: 'anyAction',
        display: this.camelFormat(entry.entityType),
      },
      {
        entityType: entry.entityType,
        action: entry.action,
        display: this.camelFormat(entry.action),
      },
    ];
  }

  public routeParamIsHandled = false;
  @Watch('auditLogTypes')
  onAuditLogTypesChange() {
    if (!this.routeParamIsHandled && this.auditLogTypes !== null) {
      // This flag ensures we only handle the url param once when the types load up
      this.routeParamIsHandled = true;
      if (this.$route.query.entityType && typeof this.$route.query.entityType === 'string') {
        this.setFilter({
          entityType: this.$route.query.entityType,
          entityAction: typeof this.$route.query.entityAction === 'string' ? this.$route.query.entityAction : undefined,
        });
      }
    }
  }

  public laterPage() {
    if (this.auditLogResult?.previousPageToken) {
      this.paginationToken = this.auditLogResult.previousPageToken;
    }
  }

  public earlierPage() {
    if (this.auditLogResult?.nextPageToken) {
      this.paginationToken = this.auditLogResult.nextPageToken;
    }
  }
}
